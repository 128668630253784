import { MaterialModule } from '@project/material.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { LoginNewComponent } from './login-new/login-new.component';
import { RegisterNewComponent } from './register-new/register-new.component';
import { AuthComponent } from './auth.component';

@NgModule({
  declarations: [AuthComponent, ForgotPasswordComponent, ResetPasswordComponent, LoginNewComponent, RegisterNewComponent],
  exports: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatIntlTelInputModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AuthModule { }
