import { ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@envconfig';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { EncrDecrServiceService } from '@project/services/encr-decr-service.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(document:click)': 'onClicks($event)',
  },
})
export class HeaderComponent implements OnInit {
  public show = false;
  fashionData: any[];
  currencyData: any;
  imageUrl: string;
  selectedCountryFlag: any;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  geoCoder: any;
  selectedCurrType: any;
  userData: any;
  status = false;
  searchData: any;
  searchvalue: any;
  subCategories: any;
  listingSearch: {
    productKind: string; category: string; subcategoryId: string; attributeValue: string; filterAttributeValueData: string; brand: string;
    vendor: string; search: any; sort: any; currentPage: number, perPage: number
  };
  subMenu: boolean;
  selectedCatagory: any;
  imageUrl1: string;
  title: any;
  catagory: any;
  catagoryDtl: any;
  show1: boolean = true;
  showL: boolean = false;
  footerContact: any;
  footerSupEmail: any;
  footerContactAdd: any;
  footerFbLink: any;
  footerTwLink: any;
  footerInstaLink: any;
  footerPintLink: any;
  newsletterData: any;
  selectedItem: any;
  catacory: any;
  userImage: any = '';
  isLogin: boolean;
  baseImgPath: string;
  social_id_flag: boolean;
  profileImage: any;
  footerYoutubeLink: any;
  cartLength: any = 0;
  cmsHeaderData: any;
  headerData: any;
  constructor(
    private api: ApiService,
    private storage: StorageService,
    private route: Router,
    private event: EventService,
    public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private cdr: ChangeDetectorRef,
    private EncrDecr: EncrDecrServiceService,
    private _eref: ElementRef,
  ) {
    this.baseImgPath = environment.IMAGE_BASE_URL1;
    this.imageUrl = environment.IMAGE_BASE_URL;
    this.imageUrl1 = environment.IMAGE_BASE_URL2
  }
  ngOnInit(): void {
    this.event.isHitHeaderMenu.next(false);
    this.selectedCurrType = 'USD';
    this.selectedCountryFlag = 'icon_1622459202627_US.png';
    const selectedData = {
      selectedCurrency: this.selectedCurrType,
      currencyValue: 1
    };
    this.event.headerFooterData.subscribe((res: any) => {
      this.headerData = res;
    });
    this.event.currencyChangeEmit(true, selectedData);
    this.getFashion();
    this.event.isLogin.subscribe(res => {
      if (res && this.storage.getUser() && this.storage.getUser().token) {
        this.isLogin = res;
        this.getProfile();
        this.getCart();
      } else {
        this.subMenu = false;
        this.isLogin = false;
        this.userData = '';
        this.userImage = '';
      }
    });
    this.event.profile.subscribe(res => {
      if (res && this.storage.getUser()?.token) {
        this.getProfile()
      }
    });
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
    this.event.isHitHeaderMenu.subscribe(res => {
      if (res) {
        this.api.get(`masterdata/categories`).subscribe((resp: any) => {
          this.fashionData = resp.data;
        });
        this.toggle(this.fashionData[0]);
        this.event.isHitHeaderMenu.next(false);
      }
    });
    this.event.cart.subscribe(res => {
      if (res) {
        this.getCart();
      } else {
        this.cartLength = 0;
      }
    });
    this.event.isSidebarClose.subscribe(res => {
      if(res){
        this.closeSideMenu();
      }
    });
  }
  

  getCart() {
    this.api.get('cart/list').subscribe((res: any) => {
      if (res?.status == 200) {
        this.cartLength = res?.data?.products?.length;
      } else {
        this.cartLength = 0;
      }
    })
  }
  getProfile() {
    this.api.get(`user/myprofile`).subscribe((res: any) => {
      if (res) {
        if (res?.data?.profileImage) {
          this.userImage = this.baseImgPath + 'user/' + res?.data?.profileImage;
          this.profileImage = res?.data?.profileImage;
        }
        if (res?.data?.social_id != "") {
          this.social_id_flag = true;
        } else {
          this.social_id_flag = false;
        }
      }
    });
  }
  onClicks(event: any, option?: string) {
    if (event?.srcElement?.id != 'createNow') {
      if (!this._eref?.nativeElement?.contains(event.target)) {
        this.headerToggel();
        this.closeSideMenu();
      }
    }
  }
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          let addCom = [];
          addCom = results[(results.length) - 1].address_components;
          addCom.map((x: any) => {
            if (x.types.includes('country')) {
              this.address = x.long_name;
              this.checkCurrency(this.address);
            }
          })
        } else {
          this.api.alert('No results found', 'error');
          this.checkCurrency(this.address);
        }
      } else {
        this.api.alert('Unable to fetch loaction : ' + status, 'error');
        this.checkCurrency(this.address);
      }

    });
  }
  checkCurrency(address?: any) {
    if (this.currencyData && address) {
      this.currencyData.map((item: any) => {
        if (address) {
          if (item.title.toLowerCase() === address.toLowerCase()) {
            this.selectedCountryFlag = item.icon;
            const selectedData = {
              selectedCurrency: item.currencyCode,
              currencyValue: item.conversionRate
            };
            this.event.currencyChangeEmit(true, selectedData);
            this.selectedCurrType = item.currencyCode;
            this.cdr.detectChanges();
          }
        } else {
          if (item.currencyCode === 'USD') {
            this.selectedCountryFlag = item.icon;
            const selectedData = {
              selectedCurrency: item.currencyCode,
              currencyValue: item.conversionRate
            };
            this.event.currencyChangeEmit(true, selectedData);
            this.selectedCurrType = item.currencyCode;
            this.cdr.detectChanges();
          }
        }
      });
    }
  }
  headerToggel() {
    this.show1 = false;
    this.showL = false;
    this.show = false;
    this.searchvalue = '';
  }
  toggle1() {
    this.searchvalue = '';
    this.show1 = true
    if (this.show) {
      this.show = false
    } else {
      this.show = true
    }
    document.body.classList.toggle('bodyActive');
  }
  toggle(catagory: any, item?: any) {
    this.show1 = true;
    this.searchvalue = '';
    if (this.show && this.selectedCatagory == catagory.categories) {
      this.show = false
    } else {
      this.showL = false
      this.show = true
      this.catagory = catagory;
      this.selectedCatagory = catagory.categories;
      this.onclick(this.selectedCatagory[0]?.subCategories, this.selectedCatagory[0]);
      if (catagory._id == 'designer') {
        this.title = 'fashion';
        if (document.querySelector('.submenu')) {
          (document.querySelector('.submenu') as HTMLElement).style.order = '1';
        }
      } else if (catagory._id == 'accessory') {
        this.title = 'accessories';
        if (document.querySelector('.submenu')) {
          (document.querySelector('.submenu') as HTMLElement).style.order = '3';
        }
      } else {
        this.title = catagory._id;
        if (document.querySelector('.submenu')) {
          (document.querySelector('.submenu') as HTMLElement).style.order = '2';
        }
      }
    }
    if (item == 'designer') {
      this.catacory = 'fashion'
    } else if (item == 'accessory') {
      this.catacory = 'accessories';
    } else if (item != 'designer' && item != 'accessory') {
      this.catacory = item;
    }
    // document.body.classList.toggle('bodyActive');
  }
  onclick(subcata: any, catagory: any) {
    this.selectedItem = catagory?.categoryId;
    this.subCategories = subcata;
    this.catagoryDtl = catagory;
  }
  toggleL() {
    this.searchvalue = '';
    if (this.showL) {
      this.showL = false;
    } else {
      this.showL = true;
      this.show = false;
    }
  }
  onProfileClick() {
    this.showL = false;
    this.show = false;
  }
  getFashion() {
    this.api.get(`masterdata/currencies`).subscribe((resp: any) => {
      this.currencyData = resp.data;
      this.checkCurrency();
    });
    this.api.get(`masterdata/categories`).subscribe((resp: any) => {
      this.fashionData = resp.data;
    });
  }
  clickEvent() {
    this.status = !this.status;
  }
  selectCurrency(ev: any) {
    this.currencyData.map((item: any) => {
      if (ev) {
        if (item.currencyCode ===  ev.value) {
          this.selectedCountryFlag = item.icon;
          const selectedData = {
            selectedCurrency:  ev.value,
            currencyValue: item.conversionRate
          };
          this.event.currencyChangeEmit(true, selectedData);
        }
      }
    });
  }
  itemCountsOnCart() {
    if (this.cartLength != 0) {
      return this.cartLength;
    }
    return 0;
  }
  openSubmenu(): void {
    this.subMenu = !this.subMenu;
  }
  linkToRoutes() {
    this.route.navigate([`/login`]);
    this.closeSideMenu();
  }
  linkToRoute(link: any) {
    this.route.navigateByUrl(`/${link}`);
    this.subMenu = false;
    this.closeSideMenu();
  }
  getSearchData(ev: any) {
    if (ev && ev !== ' ') {
      this.api.post(`product/search`, { search_text: ev }).subscribe((resp: any) => {
        this.searchData = resp.data;
      });
    } else {
      this.searchData = ' ';
    }
  }
  goToSearchList(product: any) {
    this.closeSideMenu();
    this.route.navigateByUrl('/product-details/' + product?.vendorDetails?.name.replaceAll(' ', '').split('+').join('-').toLowerCase() + '/' + product?.title.replaceAll(' ', '').split('+').join('-').toLowerCase() + '/' + product?._id);
    this.searchData = '';
    this.searchvalue = '';
  }
  closeSideMenu() {
    let menu = document.getElementById("sidemenu");
    if (menu) {
      menu.classList.add('hide');
      menu.classList.remove('fullMenu');
      document.body.classList.remove('bodyActive');
    }
  }
  showSideMenu() {
    let menu = document.getElementById("sidemenu");
    if (menu) {
      document.body.classList.add('bodyActive');
      menu.classList.remove('hide');
      menu.classList.add('fullMenu');
    }
  }
  onSubCatagoryClick(subcat: any) {
    const data = {
      productKind: '',
      category: this.catagoryDtl.categoryId,
      test: ["1234", "5678"],
      subcategoryId: subcat._id,
      attributeValue: '',
      filterAttributeValueData: '',
      brand: '',
      search: '',
      sort: 'createdAt',
      sortOrder: -1,
      currentPage: 1,
      perPage: 16
    };
    this.show1 = false;
    const subcategory = (this.catagoryDtl?.title.includes('+') ? this.catagoryDtl?.title.replaceAll(' ', '').split('+').join('-').toLowerCase() : this.catagoryDtl?.title.trim().toLowerCase());
    const catagorySubcategory = (subcategory.includes(' ') ? subcategory.split(' ').join('-').toLowerCase() : subcategory.trim().toLowerCase());
    const subcategory1 = (subcat?.title.includes('+') ? subcat?.title.replaceAll(' ', '').split('+').join('-').toLowerCase() : subcat?.title.trim().toLowerCase());
    const catagorySubcategory1 = (subcategory1.includes(' ') ? subcategory1.split(' ').join('-').toLowerCase() : subcategory1.trim().toLowerCase());
    let url = this.catacory + '/' + catagorySubcategory + '/' + catagorySubcategory1;
    url += `?category=${data.category}&subcategoryid=${data.subcategoryId}&sort=${data.sort}&sortorder=${data.sortOrder}`
    this.route.navigateByUrl(url);
    this.closeSideMenu();
  }
  logout() {
    this.storage.clearUser();
    localStorage.clear();
    sessionStorage.clear();
    this.subMenu = false;
    this.isLogin = false;
    this.userData = '';
    this.userImage = '';
    this.event.setLoginEmmit(false);
    this.event.socialData.next(false);
    this.event.cart.next(false);
    this.route.navigateByUrl('/home');
    this.closeSideMenu();
  }
}


