import { MatStepperModule } from '@angular/material/stepper';
import { AuthModule } from './../site/auth/auth.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HelperModule } from '@project/helper/helper.module';
import { MaterialModule } from '@project/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AgmCoreModule } from '@agm/core';
import { LeftPanelComponent } from './left-panel/left-panel.component';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const COMPONENTS = [HeaderComponent, FooterComponent, BreadcrumbComponent, LeftPanelComponent];

@NgModule({
  declarations: [
    COMPONENTS,
  ],
  exports: [
    COMPONENTS,
  ],
  imports: [
    CommonModule,
    HelperModule,
    MaterialModule,
    FormsModule, ReactiveFormsModule,
    RouterModule,
    AuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyACwmhmhzfuh_AJVBpdFxRZ6r3WjO0CG7s',
      libraries: ['places']
    }),
    LazyLoadImageModule.forRoot({
      preset: scrollPreset
    }),
    NgxSkeletonLoaderModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true }
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule { }