import { Component, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { EventService } from './services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  masterdata: any;
  contact_number: any;
  contact_address: any;
  support_email: any;
  facebook_url: any;
  twitter_url: any;
  instagram_url: any;
  youtube_url: any;
  pinterest_url: any;
  newsletterHeading: any;
  newsletterText: any;
  settings: any;
  cms: any;

  constructor(
    private api: ApiService,
    private event: EventService) { }

  ngOnInit(): void {
    this.getFooterData();
  }

  getFooterData() {
    this.api.get(`masterdata/settings`).subscribe((resp: any) => {
      this.settings = resp?.data;
      resp.data.forEach(element => {
        if (element?.slug == 'contact-number') {
          this.contact_number = element?.setting_value;
        } else if (element?.slug == 'contact-address') {
          this.contact_address = element?.setting_value;
        } else if (element?.slug == 'support-email') {
          this.support_email = element?.setting_value;
        } else if (element?.slug == 'facebook-url') {
          this.facebook_url = element?.setting_value;
        } else if (element?.slug == 'twitter-url') {
          this.twitter_url = element?.setting_value;
        } else if (element?.slug == 'instagram-url') {
          this.instagram_url = element?.setting_value;
        } else if (element?.slug == 'youtube-url') {
          this.youtube_url = element?.setting_value;
        } else if (element?.slug == 'pinterest-url') {
          this.pinterest_url = element?.setting_value;
        }
      });
      const data = {
        contact_number: this.contact_number,
        contact_address: this.contact_address,
        support_email: this.support_email,
        facebook_url: this.facebook_url,
        twitter_url: this.twitter_url,
        instagram_url: this.instagram_url,
        youtube_url: this.youtube_url,
        pinterest_url: this.pinterest_url,
      }
      this.event.headerFooterData.next(data);
    });
    this.api.get(`cms/homecontent`).subscribe((resp: any) => {
      this.cms = resp?.data;
      this.newsletterHeading = resp?.data?.newsletterHeading;
      this.newsletterText = resp?.data?.newsletterText;
      const data = {
        newsletterHeading: this.newsletterHeading,
        newsletterText: this.newsletterText
      }
      this.event.headerFooterCmsData.next(data);
    });

  }

}
