<div class="log-in registerNewPg">
  <div class="container">
    <div class="row">
      <div class="col-lg-7  leftImgRegister">
        <div class="left_side">
          <img height="auto" width="auto" src="./assets/images/loginImg.png" alt="img"
            class="right_img d-md-block d-none" />


        </div>
      </div>
      <div class="col-lg-5  my-auto rightFormRegister">
        <h2 class="d-md-block d-none">Sign Up</h2>
        <div class="overlay">
          <div class="scrollDiv">
            <div class="text-center d-md-none d-block">
              <img height="auto" width="auto" src="./assets/images/loginLogo.svg" alt="img" class="afterLoginLogo " />
            </div>
            <mat-horizontal-stepper [linear]="true" #stepper>
              <div class="signUpForm">
                <!-- step 1 -->
                <mat-step [stepControl]="primaryInfo">
                  <form class="form" #f="ngForm" [formGroup]="primaryInfo">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input placeholder="Name" formControlName="name" class="form-control">
                          <mat-error
                            *ngIf="primaryInfo.controls.name.touched && primaryInfo.controls.name.hasError('required')">
                            This is required
                          </mat-error>
                          <mat-error
                            *ngIf="primaryInfo.controls.name.touched && primaryInfo.controls.name.hasError('pattern')">
                            Please enter a valid name
                          </mat-error>

                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input placeholder="Email" formControlName="email" class="form-control">
                          <mat-error
                            *ngIf="primaryInfo.controls.email.touched && primaryInfo.controls.email.hasError('required')">
                            This is required
                          </mat-error>
                          <mat-error
                            *ngIf="primaryInfo.controls.email.touched && primaryInfo.controls.email.hasError('pattern')">
                            Please enter a valid email
                          </mat-error>

                        </div>
                      </div>
                      <div class="col-lg-6 countrySelectField">
                        <!-- (click)="onCountryClicked()" -->


                        <div class="form-group">

                          <!-- <select formControlName="country" (click)="setMobileCode()">
                            <option selected value="">Country</option>
                            <option *ngFor="let country of countries" [value]="country._id" [id]="country.countryCode">
                              {{country.name}}
                            </option>
                          </select> -->

                          <mat-form-field appearance="outline" class="w-100" (click)="onCountryClicked()">
                            <mat-label>Country</mat-label>
                            <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                              <mat-option *ngFor="let country of countries" [value]="country._id"
                                [id]="country.countryCode">
                                {{country.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-error
                            *ngIf="primaryInfo.controls.country.touched && primaryInfo.controls.country.hasError('required')">
                            This is required
                          </mat-error>


                        </div>
                      </div>
                      <div class="col-lg-6 phoneFieldNew">
                        <!-- [preferredCountries]="mobileCode" -->
                        <!-- <div class="form-group phnNumber">
                          <ngx-mat-intl-tel-input [preferredCountries]="[countryCode]" [enablePlaceholder]="true"
                            [enableSearch]="true" name="mobile" formControlName="mobile" style="color: white;" #mobile>
                          </ngx-mat-intl-tel-input>
                        </div> -->
                        <ng-container *ngIf="countryCode">
                          <!-- <mat-form-field appearance="legacy" class="w-100" *ngIf="countryCode"> -->
                            <ngx-mat-intl-tel-input [preferredCountries]="[countryCode]" [enablePlaceholder]="true"
                              [enableSearch]="true" name="mobile" inputPlaceholder="Mobile Number"
                              formControlName="mobile" class="phnNumber" #mobile style="color: white;">
                            </ngx-mat-intl-tel-input>
                          <!-- </mat-form-field> -->
                        </ng-container>

                        <ng-container *ngIf="!countryCode">
                          <!-- <mat-form-field appearance="legacy" class="w-100" *ngIf="!countryCode"> -->
                            <ngx-mat-intl-tel-input [preferredCountries]="['af']" [enablePlaceholder]="true"
                              [enableSearch]="true" name="mobile" inputPlaceholder="Mobile Number"
                              formControlName="mobile" class="phnNumber" #mobile style="color: white;">
                            </ngx-mat-intl-tel-input>
                          <!-- </mat-form-field> -->
                        </ng-container>


                        <mat-error *ngIf="f.form.controls['mobile']?.errors?.validatePhoneNumber">Invalid
                          Number
                        </mat-error>
                      </div>
                      <div class="col-lg-12 btnWrap">
                        <button type="button" class="btn" (click)="goForward(stepper,'primaryInfo')">Next</button>
                      </div>
                    </div>
                  </form>
                </mat-step>
                <!-- step 2 -->
                <mat-step [stepControl]="securityForm">
                  <form class="form" [formGroup]="securityForm">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <input type="password" placeholder="Password" formControlName="password" class="form-control">
                          <mat-error
                            *ngIf="securityForm.controls.password.touched && securityForm.controls.password.hasError('required')">
                            This is required
                          </mat-error>
                          <mat-error
                            *ngIf="securityForm.controls.password.touched && securityForm.controls.password.hasError('minlength')">
                            Password must be at least 8 characters
                          </mat-error>

                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <input placeholder="Email verification OTP" formControlName="emailOTP" class="form-control">
                          <mat-error
                            *ngIf="securityForm.controls.emailOTP.touched && securityForm.controls.emailOTP.hasError('required')">
                            This is required
                          </mat-error>
                          <mat-error
                            *ngIf="securityForm.controls.emailOTP.touched && securityForm.controls.emailOTP.hasError('minlength')">
                            Otp minimum 4 digit long
                          </mat-error>
                          <mat-error
                            *ngIf="securityForm.controls.emailOTP.touched && securityForm.controls.emailOTP.hasError('maxlength')">
                            Otp maximum 4 digit long
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <input placeholder="Phone number verification OTP" formControlName="mobileOTP"
                            class="form-control">
                          <mat-error
                            *ngIf="securityForm.controls.mobileOTP.touched && securityForm.controls.mobileOTP.hasError('required')">
                            This is required
                          </mat-error>
                          <mat-error
                            *ngIf="securityForm.controls.mobileOTP.touched && securityForm.controls.mobileOTP.hasError('minlength')">
                            Otp minimum 4 digit long
                          </mat-error>
                          <mat-error
                            *ngIf="securityForm.controls.mobileOTP.touched && securityForm.controls.mobileOTP.hasError('maxlength')">
                            Otp maximum 4 digit long
                          </mat-error>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="resendOtp">
                          <span *ngIf="timeLeft > 0" class="resendotpin">Resend OTP in {{timeLeft}}s.</span>
                          <button (click)="resendOTP()" [disabled]="timeLeft > 0" class="btn resendotpbtn">Resend
                            OTP</button>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="btnwrapNextSubmit">
                          <button type="button" class="btn backbtn" mat-button matStepperPrevious>Back</button>

                          <button type="button" class="btn" (click)="submit()">Sign Up</button>
                        </div>
                      </div>

                    </div>
                  </form>
                </mat-step>
              </div>
            </mat-horizontal-stepper>


            <div class="row">
              <!-- <div class="col-lg-12">
                <div class="orderbtn text-md-center">
                  <button type="button" class="btn w-100" >Sign Up</button>
                </div>
              </div> -->

              <div class="col-lg-12 text-center d-flex justify-content-md-center align-items-center text_margin">
                <p>Or sign up with</p>
                <button class="social_login">
                  <img height="auto" width="auto" src="./assets/images/googleIcon.svg" alt="img" />
                  <div id="buttonDiv"></div>
                </button>
                <button class="social_login" (click)="submitLogin()">
                  <img height="auto" width="auto" src="./assets/images/facebookIcon.svg" alt="img" />
                </button>
              </div>
              <div class="col-lg-12 text-md-center text_margin">
                <p>Already have an account? <a routerLink="/login">Sign In</a> </p>
              </div>
              <!-- <div class="col-lg-12 text-center text_margin down_text d-md-none d-block  justify-content-center">
                <p class="text-center">Designed by <a>Tom Cruise</a></p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>