import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@project/services/api.service';
import { EventService } from '@project/services/event.service';
import { StorageService } from '@project/services/storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  forgotForm: UntypedFormGroup;
  userId: any;

  constructor(
    private api: ApiService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    public storage: StorageService,
    public event: EventService,

  ) {
    this.forgotForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new UntypedFormControl('', [Validators.required]),
    });

  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params: any) => {
      this.userId = params.id;
    });
  }

  matchPassword(eve: any): void {
    if (eve) {
      if (
        this.forgotForm.controls.password.value &&
        this.forgotForm.controls.confirmPassword.value
      ) {
        if (
          this.forgotForm.controls.password.value !==
          this.forgotForm.controls.confirmPassword.value
        ) {
          this.forgotForm.controls.confirmPassword.setErrors({
            noMatch: true,
          });
        } else {
          this.forgotForm.controls.confirmPassword.clearValidators();
          this.forgotForm.controls.confirmPassword.updateValueAndValidity();
        }
      }
    }
  }


  @HostListener("window:keydown.enter", ["$event"])
  updatePassword() {
    if (this.forgotForm.valid) {
      if (this.userId) {
        const payLoad = {
          password: this.forgotForm.value.password,
          authCode: this.userId
        };
        this.api.post(`user/updatepassword`, payLoad).subscribe((resp: any) => {
          if (resp.status === 200) {
            this.api.alert(resp.message, 'success');
            this.router.navigate(['/home']);
          } else {
            this.api.alert(resp.message, 'warning');
          }
        }, err => {
          this.api.alert(err.message, 'error');
        });
      } else {
        this.api.alert('User Id Is not available', 'error');
      }
    } else {
      this.forgotForm.markAllAsTouched();
    }
  }





}
