import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private api: ApiService
  ) {
    this.forgotForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
    });
  }

  ngOnInit(): void { }

  save() {
    if (this.forgotForm.valid) {
      this.api.post(`user/resetpassword`, this.forgotForm.value).subscribe((resp: any) => {
        if (resp?.status == 200) {
          this.api.alert(resp.message, 'success');
          this.dialogRef.close();
        } else {
          this.api.alert(resp.message, 'warning');
          this.dialogRef.close();
        }
      }, err => {
        this.api.alert(err.message, 'error');
      });
    } else {
      this.forgotForm.markAllAsTouched();
    }
  }


}
