<div class="log-in">
  <div class="container">
    <div class="row">
      <div class="col-md-7  d-md-block d-none">
        <div class="left_side">
          <img height="auto" width="auto" src="./assets/images/loginImg.png" alt="img"
            class="right_img d-md-block d-none" />


        </div>
      </div>
      <div class="col-md-5  my-auto">
        <h2 class="d-md-block d-none">Login</h2>
        <div class="overlay">
          <div class="scrollDiv">
            <div class="text-center d-md-none d-block">
              <img height="auto" width="auto" src="./assets/images/loginLogo.svg" alt="img" routerLink="/"
                style="cursor: pointer;" class="afterLoginLogo " />
            </div>
            <form [formGroup]="loginForm">
              <div class="form-group">
                <input type="email" class="form-control" aria-describedby="emailHelp" placeholder="Email"
                  formControlName="email" />
                <mat-error
                  *ngIf="loginForm?.controls?.email?.touched && loginForm?.controls?.email?.hasError('required')">
                  Email is required
                </mat-error>
                <mat-error *ngIf="
                loginForm?.controls?.email?.touched &&
                loginForm?.controls?.email?.hasError('pattern')
              ">Please enter a valid Email</mat-error>
              </div>
              <div class="form-group">
                <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                <mat-error
                  *ngIf="loginForm?.controls?.password?.touched && loginForm?.controls?.password?.hasError('required')">
                  Password is required
                </mat-error>

              </div>
            </form>
            <div class="row">
              <div class="col-lg-12">
                <div class="orderbtn text-md-center">
                  <button type="button" class="btn w-100 signinBtn" (click)="userLogin()">
                    <ng-container *ngIf="isLoader">
                      <mat-spinner></mat-spinner>
                      <!-- please wait ... -->
                    </ng-container>
                    <ng-container *ngIf="!isLoader">Sign In</ng-container>
                  </button>
                </div>
              </div>
              <div class="col-lg-12 text-md-center mt-3" (click)="forgotPassword()">
                <a>Forgot Password?</a>
              </div>
              <div class="col-lg-12 text-center d-flex justify-content-md-center align-items-center text_margin">
                <p>Or sign in with</p>
                <button class="social_login">
                  <img height="auto" width="auto" src="./assets/images/googleIcon.svg" alt="img" />
                  <div id="buttonDiv"></div>
                </button>
                <button class="social_login" (click)="submitLogin()">
                  <img height="auto" width="auto" src="./assets/images/facebookIcon.svg" alt="img" />
                </button>
              </div>
              <div class="col-lg-12 text-md-center text_margin">
                <p>Didn’t have an account? <a routerLink="/sign-up">Sign Up</a> </p>
              </div>
              <div class="col-lg-12 text-center text_margin down_text d-md-none d-block">
                <p>Designed by <a>Tom Cruise</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>