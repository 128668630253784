<header class="page_header">
  <div class="header-top">
    <p>Free Shipping & Returns On All Orders</p>
  </div>
  <div class="container-fluid">
    <div class="main_header">
      <div class="row mob_row headerWrapper">
        <div class="col-xl-7 col-lg-6 col-md-5 col-4 ps-static left-header-logo-pos">
          <div class="heder-left">
            <a routerLink="/" class="headerlogo" (click)="headerToggel()">
              <img src="./assets/images/logo1.svg" alt="shopdrop_logo">
            </a>
            <div class="hide" id="sidemenu">
              <div class="overlay" (click)="closeSideMenu()">&nbsp;</div>
              <div class="backDrop"></div>
              <ul class="menuSection">
                <ng-container *ngFor="let item of fashionData">
                  <li class="mega-menu-c">
                    <a *ngIf="item?._id != 'designer' && item?._id != 'accessory'"
                      (click)="toggle(item,item?._id)">{{item._id}}</a>
                    <a *ngIf="item?._id == 'designer'" (click)="toggle(item,item?._id)">fashion</a>
                    <a *ngIf="item?._id == 'accessory'" (click)="toggle(item,item?._id)">accessories</a>
                  </li>
                </ng-container>
                <ng-container *ngIf="show1">
                  <div [ngClass]="show ? 'submenu fadeIn active' : 'submenu fadeIn'">
                    <div class="row megaMenu__wrapper">
                      <div class="col-md-3">
                        <div class="mega-menu__sidebar">
                          <h4 class="title">{{title}}<span></span></h4>
                          <ul>
                            <ng-container *ngFor="let catagory of selectedCatagory; let i = index">
                              <li (click)="onclick(catagory?.subCategories,catagory)"
                                [ngClass]="{'active': selectedItem == catagory?.categoryId}">{{catagory?.title}}
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-9">
                        <div class="show-select__produsctlist">
                          <div class="row">
                            <div class="col-xl-3 col-lg-4 col-md-6" *ngFor="let subcat of subCategories">
                              <div class="product__lists-area" (click)="onSubCatagoryClick(subcat)">
                                <img [src]="imageUrl + 'category/thumb/' + subcat?.image" alt="subcat_img">
                                <div class="middle">
                                  <h3 class="text">{{subcat?.title}}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <li class="learn-more-btn-sec">
                  <a (click)="toggleL()">Learn More</a>
                  <div class="learn-more-page-main-sec" [ngClass]="showL ? 'activeL' : ''">
                    <div class="container  cont_max">
                      <div class="row linksecMenu">
                        <div class="col-md-4">
                          <div class="know-more-left-side-learn-more">
                            <h2>QUICK LINKS</h2>
                            <ul (click)="toggleL()">
                              <li><a routerLink='/why-custom' routerLinkActive="active">Why Custom</a></li>
                              <li><a routerLink='/how-it-works' routerLinkActive="active">How It Works</a></li>
                              <li><a routerLink='/perfect-fit-guarantee' routerLinkActive="active">Perfect Fit
                                  Guarantee</a></li>
                              <li><a routerLink='/how-it-made' routerLinkActive="active">How It’s Made</a></li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-4 otherlinksmenu">
                          <div class="middle-side-learn-more">
                            <h2>OTHER LINKS</h2>
                            <ul (click)="toggleL()">
                              <li><a routerLink="/what-is-shopdrop" routerLinkActive="active">What is ShopDrop?</a>
                              </li>
                              <li><a routerLink='/our-fabrics' routerLinkActive="active">Our Fabrics</a></li>
                              <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
                              <li *ngIf="!isLogin" class="mobMyAccLink"><a routerLink="/login"
                                  routerLinkActive="active">My Account</a></li>
                            </ul>
                          </div>
                          <div class="we-are-social-sec">
                            <h2>We are social</h2>
                            <div class="social-icon-sec">
                              <ul _ngcontent-nwh-c182="" class="socialLink" (click)="toggleL()">
                                <li _ngcontent-nwh-c182=""><a _ngcontent-nwh-c182="" target="blank"
                                    [href]="headerData?.instagram_url"><i _ngcontent-nwh-c182="" aria-hidden="true"
                                      class="fa fa-instagram"></i></a></li>
                                <li _ngcontent-nwh-c182=""><a _ngcontent-nwh-c182="" target="blank"
                                    [href]="headerData?.facebook_url"><i _ngcontent-nwh-c182="" aria-hidden="true"
                                      class="fa fa-facebook"></i></a></li>
                                <li _ngcontent-nwh-c182=""><a _ngcontent-nwh-c182="" target="blank"
                                    [href]="headerData?.twitter_url"><i _ngcontent-nwh-c182="" aria-hidden="true"
                                      class="fa fa-twitter"></i></a></li>
                                <li _ngcontent-nwh-c182=""><a _ngcontent-nwh-c182="" target="blank"
                                    [href]="headerData?.youtube_url"><i _ngcontent-nwh-c182="" aria-hidden="true"
                                      class="fa fa-youtube-play"></i></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 mobAccount">
                          <div class=" contact-us-learn-more-sec my-account-mob">
                            <ng-container *ngIf="isLogin">
                              <h2>My Account</h2>
                              <div class="itemwrapper">
                                <ul class="">
                                  <li>
                                    <img
                                      [src]=" profileImage ? baseImgPath + 'user/' + profileImage : 'assets/images/headerprofile2.png'"
                                      alt="shopdrop_profile_img" class="userIcon"><span><a
                                        (click)="linkToRoute('profile')"> My
                                        Profile </a></span>
                                  </li>
                                  <li>
                                    <i class="material-icons"> list </i><span> <a (click)="linkToRoute('orders')">My
                                        Orders</a></span>
                                  </li>
                                  <li>
                                    <i class="material-icons"> favorite
                                    </i> <span> <a (click)="linkToRoute('wishlist')">My Wishlist</a></span>
                                  </li>
                                  <ng-container *ngIf="!social_id_flag">
                                    <li>
                                      <i class="material-icons"> lock
                                      </i><span><a (click)="linkToRoute('change-password')">Reset Password</a></span>
                                    </li>
                                  </ng-container>
                                  <li>
                                    <i class="material-icons"> logout </i>
                                    <span> <a (click)="logout()">Logout</a></span>
                                  </li>
                                </ul>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div class="col-md-4 conactmenu">
                          <div class="contact-us-learn-more-sec">
                            <h2>CONTACT US</h2>
                            <ul>
                              <li>
                                <mat-icon _ngcontent-rdr-c182="" role="img"
                                  class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                                  data-mat-icon-type="font"> location_on</mat-icon>
                                <span _ngcontent-rdr-c182="">{{headerData?.contact_address}}</span>
                              </li>
                              <li _ngcontent-rdr-c182="">
                                <mat-icon _ngcontent-rdr-c182="" role="img"
                                  class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                                  data-mat-icon-type="font">call</mat-icon>
                                <span _ngcontent-rdr-c182=""> <a
                                    [href]="'tel:' + headerData?.contact_number">{{headerData?.contact_number}}</a>
                                </span>
                              </li>
                              <li _ngcontent-rdr-c182="">
                                <mat-icon _ngcontent-rdr-c182="" role="img"
                                  class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true"
                                  data-mat-icon-type="font">email</mat-icon>
                                <span _ngcontent-rdr-c182=""><a
                                    [href]="'mailto:' + headerData?.support_email">{{headerData?.support_email}}</a></span>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                      <div class="currency">
                        <ul>
                          <li>
                            <span> <a>Currency : {{selectedCurrType}}</a></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <button aria-label="close_h" class="toggleClose" (click)="closeSideMenu()">
                  <mat-icon>close</mat-icon>
                </button>
                <li>
                  <div class="header-right mobHeaderSearch">
                    <div class="search-main">
                      <div class="search-header">
                        <div>
                          <input type="text" placeholder="Search....." [(ngModel)]="searchvalue"
                            (ngModelChange)="getSearchData($event)">
                          <button aria-label="h1" type="button">
                            <img src="assets/images/search-icon.svg" alt="search">
                          </button>
                        </div>
                        <div class="searchData" *ngIf="searchData && searchData?.length > 0">
                          <ul claas="seacrh-ul">
                            <li *ngFor="let item of searchData"><a (click)="goToSearchList(item)">{{item?.title}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 col-md-7 col-8 right-sec-header-pos">
          <div class="header-right desktopHeaderRight">

            <div class="search-main">
              <div class="search-header">
                <div>
                  <input type="text" placeholder="Search....." [(ngModel)]="searchvalue"
                    (ngModelChange)="getSearchData($event)">
                  <button aria-label="h2" type="button">
                    <img src="assets/images/search-icon.svg" alt="search1">
                  </button>
                </div>
                <div class="searchData" *ngIf="searchvalue && searchData && searchData?.length > 0">
                  <ul claas="seacrh-ul">
                    <li *ngFor="let item of searchData"><a (click)="goToSearchList(item)">{{item?.title}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="icon-sec">
              <div class="profile desktopProfile" (click)="onProfileClick()">
                <ng-container *ngIf="!isLogin">
                  <button aria-label="h3" (click)="linkToRoutes()"><img src="assets/images/headerprofile2.png"
                      alt="shopdrop_header_profile" class="userIcon"></button>
                </ng-container>
                <ng-container *ngIf="isLogin">
                  <button aria-label="h4" [matMenuTriggerFor]="menu"><img
                      [src]=" profileImage ? baseImgPath + 'user/' + profileImage : 'assets/images/headerprofile2.png'"
                      alt="shopdrop_profile_img" class="userIcon"></button>
                  <div class="subMenu">
                    <mat-menu #menu="matMenu" class="userDropMenu">
                      <button aria-label="h5" mat-menu-item (click)="linkToRoute('profile')"><i class="material-icons">
                          person </i>
                        <span>My Profile</span></button>
                      <button aria-label="h6" mat-menu-item (click)="linkToRoute('orders')"><i class="material-icons">
                          list </i>
                        <span>My Orders</span></button>
                      <button aria-label="h7" mat-menu-item (click)="linkToRoute('wishlist')"><i class="material-icons">
                          favorite
                        </i><span>My Wishlist</span></button>
                      <ng-container *ngIf="!social_id_flag">
                        <button aria-label="h8" mat-menu-item (click)="linkToRoute('change-password')"><i
                            class="material-icons"> lock
                          </i><span>Reset
                            Password</span></button>
                      </ng-container>
                      <button aria-label="h9" mat-menu-item (click)="logout()"><i class="material-icons"> logout </i>
                        <span>Logout</span></button>
                    </mat-menu>
                  </div>
                </ng-container>
              </div>

              <div class="flag_icon">
                <!-- <img src="./assets/images/usa_flag.png" alt=""> -->
                <!-- <select (change)="selectCurrency($event)" class="border-0 py-0" [(ngModel)]="selectedCurrType">
                  <option *ngFor="let item of currencyData" [value]="item?.currencyCode">{{item?.title}}</option>
                </select> -->
                <mat-form-field appearance="fill">
                  <!-- <mat-label>Select Currency</mat-label> -->
                  <mat-select (selectionChange)="selectCurrency($event)" class="border-0 py-0" [(ngModel)]="selectedCurrType">
                    <mat-option *ngFor="let item of currencyData" [value]="item?.currencyCode">{{item?.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <span *ngIf="selectedCountryFlag">
                  <img src="{{imageUrl}}currencies/{{selectedCountryFlag}}" width="20" height="15" alt="">
                </span>
              </div>

              <a routerLink="/cart" class="cartsec" (click)="headerToggel()">
                <img src="./assets/images/cart-icon.svg" alt="cart_icon" class="cart-header">
                <span class="cart-count">{{itemCountsOnCart()}}</span>
              </a>
              <ul>
                <li class="mobilemenu_end">
                  <button aria-label="h10" class="toggleBar" (click)="showSideMenu()"><img
                      src="./assets/images/menu-bar.svg" alt="togglebar"></button>
                  <ng-container *ngIf="!isLogin">
                    <button aria-label="h11" class="profile_menu d-none"><img src="assets/images/headerprofile2.png"
                        alt="profile_logo" class="userIcon" (click)="linkToRoutes()"></button>
                  </ng-container>
                  <ng-container *ngIf="isLogin">
                    <button aria-label="h12" class="profile_menu d-none" [matMenuTriggerFor]="menu"><img
                        [src]=" profileImage ? baseImgPath + 'user/' + profileImage : 'assets/images/headerprofile2.png'"
                        alt="profile_shopdrop" class="userIcon"></button>
                    <div class="subMenu">
                      <mat-menu #menu="matMenu" class="userDropMenu">
                        <button aria-label="h13" mat-menu-item (click)="linkToRoute('profile')"><i
                            class="material-icons"> person </i>
                          <span>My Profile</span></button>
                        <button aria-label="h14" mat-menu-item (click)="linkToRoute('orders')"><i
                            class="material-icons"> list </i>
                          <span>My Orders</span></button>
                        <button aria-label="h15" mat-menu-item (click)="linkToRoute('wishlist')"><i
                            class="material-icons"> favorite
                          </i><span>My Wishlist</span></button>
                        <ng-container *ngIf="!social_id_flag">
                          <button aria-label="h16" mat-menu-item (click)="linkToRoute('change-password')"><i
                              class="material-icons"> lock
                            </i><span>Reset
                              Password</span></button>
                        </ng-container>
                        <button aria-label="h17" mat-menu-item (click)="logout()"><i class="material-icons"> logout </i>
                          <span>Logout</span></button>
                      </mat-menu>
                    </div>
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>