import { Component, OnInit } from '@angular/core';
import { EventService } from '@project/services/event.service';

@Component({
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss']
})
export class LeftPanelComponent implements OnInit {
  title: any;

  constructor(private event: EventService) { }

  ngOnInit(): void {
    this.event.isCmsHeader.subscribe((res: any) => {
      this.title = res;
    })
  }

  toggle() {
    let ele = document.getElementById('panel');
    if (ele.classList.contains('active')) {
      ele.classList.remove('active')
    } else {
      ele.classList.add('active');
    }
  }

}
