import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from './api.service';
import { EventService } from './event.service';
import jwt_decode from 'jwt-decode';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SocalloginService implements OnInit {

  constructor(private api: ApiService, private event: EventService, @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.gmailInit();
      this.fbInit();
    }
  }
  ngOnInit(): any {
  }
  fbInit() {
    (window as any).fbAsyncInit = function () {
      (window as any).FB.init({
        appId: '1471539129978100',
        cookie: true,
        xfbml: true,
        version: 'v14.0'
      });
      (window as any).FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = (d.getElementsByTagName(s)[0] as HTMLElement);
      if (d.getElementById(id)) { return; }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      (js as HTMLScriptElement).src = "https://connect.facebook.net/en_US/sdk.js";
      (fjs.parentNode as any).insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  submitLogin() {
    (window as any).FB.login((response: any) => {
      if (response.authResponse) {
        (window as any).FB.api('/me?fields=email,name', (res: any) => {
          const data = {
            email: res.email,
            social_id: res.id,
            registerType: 'facebook'
          }
          this.event.socialData.next(data)
        });

      }
      else {
        this.event.socialData.next(false)

      }
    }, { scope: 'email,public_profile', return_scopes: true });

  }

  gmailDec(src: string) {
    return new Promise(function (resolve, reject) {
      var s;
      s = document.createElement('script');
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  }

  gmailInit() {
    this.gmailDec('https://accounts.google.com/gsi/client')
      .then((res: any) => {
        (google as any).accounts.id.initialize({
          client_id: environment.GOOGLE_CLIENT_ID,
          callback: (res) => {
            const responsePayload = this.decodeJwtResponse(res.credential);
            if (responsePayload) {
              const data = {
                email: responsePayload.email,
                social_id: responsePayload.sub,
                registerType: 'google'
              }
              this.event.socialData.next(data)
            }
            else {
              this.event.socialData.next(false)
            }
          }
        });
        (google as any).accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large" }
        );
      })
      .catch((res: any) => {

      })
  }

  decodeJwtResponse(credential: any): any {
    try {
      return jwt_decode(credential);
    } catch (Error) {
      return null;
    }
  }

}
