import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-ts';

@Injectable({
  providedIn: 'root'
})
export class EncrDecrServiceService {
  keys: string;

  constructor() {
    this.keys = '123456$#@$^@1ERF';
  }


  setData(value: any) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(value), this.keys).toString();
    } catch (e) {
      return e;
    }
  }

  getData(value: any) {

    try {
      const bytes = CryptoJS.AES.decrypt(value, this.keys);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return value;
    } catch (e) {
    }
  }

}
