<section class="loginRegWrap px-2">
    <h2>
        Forgot Password
    </h2>

    <form [formGroup]="forgotForm">
        <div class="form-group">
            <label></label>
            <input class="form-control" placeholder="Email" formControlName="email">


            <mat-error *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.hasError('required')">
                This is required
            </mat-error>
            <mat-error *ngIf="forgotForm.controls.email.touched && forgotForm.controls.email.hasError('pattern')">
                Please enter a valid email
            </mat-error>
        </div>
    </form>
    <button class="w-100 btn" matRipple (click)="save()">SUBMIT</button>

</section>