<div class="log-in">
    <div class="container">
        <div class="row">
            <div class="col-md-7  d-md-block d-none">
                <div class="left_side">
                    <img height="auto" width="auto" src="./assets/images/loginImg.png" alt="img"
                        class="right_img d-md-block d-none" />


                </div>
            </div>
            <div class="col-md-5  my-auto">
                <h2 class="d-md-block d-none">Forgot Password</h2>
                <div class="overlay">
                    <div class="scrollDiv">
                        <div class="text-center d-md-none d-block">
                            <img height="auto" width="auto" src="./assets/images/loginLogo.svg" alt="img" routerLink="/"
                                style="cursor: pointer;" class="afterLoginLogo " />
                        </div>
                        <form [formGroup]="forgotForm">
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="  New Password"
                                    formControlName="password">
                                <mat-error
                                    *ngIf="forgotForm.controls.password.touched && forgotForm.controls.password.hasError('required')">
                                    This is required
                                </mat-error>
                                <mat-error
                                    *ngIf="forgotForm.controls.password.touched && forgotForm.controls.password.hasError('minlength')">
                                    Password must be at least 8 characters
                                </mat-error>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="  Confirm Password"
                                    formControlName="confirmPassword">
                                <mat-error
                                    *ngIf="forgotForm.controls.confirmPassword.touched && forgotForm.controls.confirmPassword.hasError('required')">
                                    This is required
                                </mat-error>
                                <mat-error
                                    *ngIf="forgotForm.controls.confirmPassword.touched && forgotForm.controls.confirmPassword.value != forgotForm.controls.password.value">
                                    Password and Confirm Password must be same
                                </mat-error>

                            </div>
                        </form>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="orderbtn text-md-center">
                                    <button type="button" class="btn w-100" (click)="updatePassword()">Update
                                        Password</button>
                                </div>
                            </div>

                            <!-- <div class="col-lg-12 text-md-center text_margin">
                                <p>Didn’t have an account? <a routerLink="/sign-up">Sign Up</a> </p>
                            </div> -->
                            <!-- <div class="col-lg-12 text-center text_margin down_text d-md-none d-block">
                                <p>Designed by <a>Tom Cruise</a></p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>