<div class="left-panel" id="panel">
    <h6>Info</h6>
    <ul>
        <h4 (click)="toggle()">{{title}}<span>
                <mat-icon>expand_less</mat-icon>
            </span></h4>
        <li><a routerLink='/how-it-works' routerLinkActive="active">How It Works</a></li>
        <li><a routerLink='/why-custom' routerLinkActive="active">Why Custom</a></li>
        <li><a routerLink='/perfect-fit-guarantee' routerLinkActive="active">Perfect Fit Guarantee</a></li>
        <li><a routerLink='/how-it-made' routerLinkActive="active">How It’s Made</a></li>
        <li><a routerLink='/our-fabrics' routerLinkActive="active">Our Fabrics</a></li>
        <li><a routerLink='/what-is-shopdrop' routerLinkActive="active">What is Shopdrop</a></li>
        <li><a routerLink='/contact' routerLinkActive="active">Contact Us</a></li>
    </ul>
</div>